
<template>
  <div class="Aftershou_A_I">
    <div class="headNavs">
      <div style="display:flex">
        <el-menu
          :default-active="activeIndex"
          class="el-menu-demo"
          mode="horizontal"
          @select="handleSelect">
          <el-menu-item index="1">初级职称</el-menu-item>
          <el-menu-item index="2">中级职称</el-menu-item>
          <el-menu-item index="3">高级职称</el-menu-item>
          <el-menu-item index="4">职称论文</el-menu-item>
          <el-menu-item index="5">职称学时</el-menu-item>
        </el-menu>
      </div>
      <div class="headDetails">说明：为售后服务老师量身打造的板块，更好提升服务效率，提高服务品质</div>
    </div>


    <Intermediate v-if="isShow == 2 "></Intermediate>  <!-- 中级职称 -->
    <Seniortitla v-if="isShow == 3 "></Seniortitla>   <!-- 高级职称 -->
    <Credithours v-if="isShow == 4 "></Credithours>    <!-- 职称学时 -->
    <Thesistitla v-if="isShow == 5 "></Thesistitla>   <!-- 职称论文 -->
    <div v-if="isShow == 1 ">
      <div class="capitalNav">
        <div style="display:flex;justify-content: space-between;">
          <div class="NavLi">
            <div class="NavLiName">申报年份：</div>
            <div class="NavLiIpt">
              <el-date-picker v-model="search.declare_year" type="year" placeholder="选择年份" :clearable="false"></el-date-picker>
            </div>
          </div>
          <div class="NavLi">
            <div class="NavLiName">申报地区：</div>
            <div class="NavLiIpt">
              <el-select v-model="search.declare_address" placeholder="请选择申报地区">
                <el-option v-for="item in BBBBB_content.sales_declare_address" :key="item.value" :label="item.content" :value="item.value"></el-option>
              </el-select>
            </div>
          </div>
          <div class="NavLi">
            <div class="NavLiName">评价方式：</div>
            <div class="NavLiIpt">
              <el-select v-model="search.evaluate_type" placeholder="请选择评价方式">
                <el-option v-for="item in BBBBB_content.sales_evaluate_type" :key="item.value" :label="item.content" :value="item.value"></el-option>
              </el-select>
            </div>
          </div>
          <div class="NavLi">
            <div class="NavLiName">业务员：</div>
            <div class="NavLiIpt">
              <el-select v-model="search.business_id" placeholder="请选择业务员">
                <el-option v-for="item in BusinessList" :key="item.id" :label="item.username"  :value="item.id"></el-option>
              </el-select>
            </div>
            <div class="NavLiQuery" style="margin-left: 24px;" @click="BtnSearch()">查询</div>
          </div>
          <div class="NavLi"></div>
          <div class="NavLi"></div>
        </div>
        <div style="display:flex;justify-content: space-between;margin-top:20px">
          <div class="NavLi">
            <div class="NavLiName">单位性质：</div>
            <div class="NavLiIpt">
              <el-select v-model="search.company_type" placeholder="请选择单位性质" style="width:220px">
                <el-option v-for="(item , i) in BBBBB_list.company_type" :key="i" :label="item" :value="i"></el-option>
              </el-select>
            </div>
          </div>
          <div class="NavLi">
            <div class="NavLiName">学历层次：</div>
            <div class="NavLiIpt">
              <el-select v-model="search.education_arrangement" placeholder="请选择学历层次">
                <el-option v-for="(item , i) in BBBBB_list.education_arrangement" :key="i" :label="item" :value="i"></el-option>
              </el-select>
            </div>
          </div>
          <div class="NavLi">
            <div class="NavLiName">申报系列：</div>
            <div class="NavLiIpt">
              <el-select v-model="search.declare_series" placeholder="请选择申报系列">
                <el-option v-for="item in BBBBB_content.sales_declare_series" :key="item.value" :label="item.content" :value="item.value"></el-option>
              </el-select>
            </div>
          </div>
          <div class="NavLi">
            <div class="NavLiName">站点：</div>
            <div class="NavLiIpt">
              <el-select v-model="search.site_id" placeholder="全部">
                <el-option v-for="item in SiteList" :key="item.site_id" :label="item.name" :value="item.site_id"></el-option>
              </el-select>
            </div>
            <div class="NavLiReset"  style="margin-left: 24px;" @click="BtnSearch(false)">重置</div>
          </div>
          <div class="NavLi"></div>
          <div class="NavLi"></div>
        </div>
      </div>
      
      <div class="TablesList">
        <div class="TablesADD">
          <div style="width:150px;margin-right:24px">
            <el-select v-model="type" placeholder="请选择线索" @change="Chang_ses">
              <el-option v-for="item in MMD" :key="item.id" :label="item.name" :value="item.id"></el-option>
            </el-select> 
          </div>
          <div class="NavLiQuery" @click="showAdd()" v-if="TextMoMo('workbench:sales:primary:add')"><i class="el-icon-plus"></i>人员登记</div>
          <div class="NavLiDelte" @click="Add_Del()" v-if="TextMoMo('workbench:sales:primary:del')"><i class="el-icon-delete"></i>批量删除</div>
          <div class="TableIpt"> 
            <input @keyup.enter.native="BtnSearch()" type="text" v-model="search.keyword" placeholder="请输入姓名">
            <div class="seso" @click="BtnSearch()"><i class="el-icon-search"></i></div>
          </div>
        </div>
        <el-table
          :data="tableData"
          class="table"
          ref="multipleTable"
          header-cell-class-name="table-header"
          @selection-change="Selection">
          <el-table-column type="selection" width="55" align="center"></el-table-column>
          <el-table-column prop="id" label="ID" width="55" align="center" ></el-table-column>
          <el-table-column align="center" prop="site.name" label="站点"></el-table-column>
          <el-table-column align="center" prop="username" label="服务对象"></el-table-column>
          <el-table-column align="center" prop="business.username" label="业务员"></el-table-column>
          <el-table-column align="center" prop="declare_address_text" label="申报地区"></el-table-column>
          <el-table-column align="center" prop="declare_year" label="申报年份"></el-table-column>
          <el-table-column align="center" prop="declare_major_text" label="申报专业"></el-table-column>
          <el-table-column align="center" prop="education_arrangement_text" label="学历层次"></el-table-column>
          <el-table-column align="center" prop="graduate_major" label="学历专业"></el-table-column>
          <el-table-column align="center" label="操作" width="200">
            <template #default="scope" >
                <div class="el_butto">
                  <el-button
                  type="text" 
                  @click="HandleTransfer(scope.row)"
                  >转移</el-button>
                </div>
                <div class="el_butto">
                  <el-button
                  type="text" v-if="TextMoMo('workbench:sales:primary:edit')" 
                  @click="HandleEdit(scope.row)"
                  >编辑</el-button>
                </div>
              <div class="el_butto rere" >
                  <el-button
                  type="text"
                  class="red" v-if="TextMoMo('workbench:sales:primary:del')" 
                  @click="HandleDel(scope.row)"
                  >删除</el-button>
              </div>
            </template>
          </el-table-column>
        </el-table>
        <div class="block">
          <el-pagination
              background
              @current-change="handleCurrentChange"
              :current-page="page.currentPage"
              :page-size="page.pageSize"
              layout="total, prev, pager, next, jumper"
              :total="total">
          </el-pagination>
        </div>
      </div>
    </div>


    <div class="show" v-if="ruleFormRef">
      <div class="rightPanel-background"/>
      <div class="EditNew" style="width:820px">
        <div class="EditNewTop">
          <div>{{titleMath}}</div>
          <div @click="Close()"><i class="el-icon-close"></i></div>
        </div>
        <div class="headNavs" style="padding:0 26px" v-if="chakan111 == 1">
          <el-menu
            :default-active="acndex"
            class="el-menu-demo"
            mode="horizontal"
            @select="handleSelect2">
            <el-menu-item index="1">人员信息</el-menu-item>
            <el-menu-item index="2">进程记录</el-menu-item>
            <el-menu-item index="3">办理进度</el-menu-item>
            <el-menu-item index="4">附件资料</el-menu-item>
          </el-menu>
        </div>
        <el-form>
          <div class="EditNewForm" v-if="isRow == 1">
            <el-form-item class="EditNewFormBtns">
              <div class="EditNewFormItemName">订单验证：</div>
              <div class="EditNewFormItemIpt aaa">
                <input v-model.trim="order" placeholder="请输入本次服务对象对应的订单号"/>
                <span class="Modification" style="margin-right: 0%;" @click="Query()">点击验证</span>
              </div>
            </el-form-item>
            <el-form-item class="EditNewFormBtns">
              <div class="EditNewFormItemName" v-if="D_yan2 == 1">验证成功，系统有此订单，可查看详情</div>
              <div class="EditNewFormItemName" v-if="D_yan2 == 2">验证失败，系统查无订单，请重新输入</div>
            </el-form-item>
            <el-form-item class="EditNewFormBtns" v-if="D_yan2 == 1">
              <div class="EditNewFormItemName">订单验证：</div>
              <div class="EditNewFormItemIpt">
                <el-input v-model.trim="Order.order_no" style="width: 79%;" placeholder="请输入本次服务对象对应的订单号" disabled/>
              </div>
            </el-form-item>
            <el-form-item class="EditNewFormBtns">
              <div class="EditNewFormItemName">一、订单基本信息
                <span class="Modification" @click="ChaKan()" v-if="D_yan2 == 1">查看详情</span>
              </div>
            </el-form-item>
            <el-form-item class="EditNewFormItemTube">
              <div class="EditNewFormItemName">买家姓名</div>
              <div class="EditNewFormItemIpt">
                <el-input v-model="Order.goodsone.user_nickname" disabled placeholder="请验证订单号"></el-input>
              </div>
            </el-form-item>
            <el-form-item class="EditNewFormItemTube">
              <div class="EditNewFormItemName">买家联系方式</div>
              <div class="EditNewFormItemIpt">
                <el-input v-model="Order.goodsone.user_mobile" disabled placeholder="请验证订单号"></el-input>
              </div>
            </el-form-item>
            <el-form-item class="EditNewFormItemTube">
              <div class="EditNewFormItemName">订单状态</div>
              <div class="EditNewFormItemIpt">
                <el-input v-model="Order.order_status_text" disabled placeholder="请验证订单号"></el-input>
              </div>
            </el-form-item>
            <el-form-item class="EditNewFormItemTube">
              <div class="EditNewFormItemName">服务对象</div>
              <div class="EditNewFormItemIpt">
                <el-input v-model="val2" placeholder="请验证订单号" disabled></el-input>
              </div>
            </el-form-item>
            <el-form-item class="EditNewFormItemTube">
              <div class="EditNewFormItemName">购买项目</div>
              <div class="EditNewFormItemIpt">
                <el-input v-model="Order.goodsone.goods_name" disabled placeholder="请验证订单号"></el-input>
              </div>
            </el-form-item>
            <el-form-item class="EditNewFormItemTube">
              <div class="EditNewFormItemName">购买数量</div>
              <div class="EditNewFormItemIpt">
                <el-input v-model="Order.goodsone.total_num" disabled placeholder="请验证订单号"></el-input>
              </div>
            </el-form-item>
          </div>
          <div class="EditNewForm" v-if="isRow == 2">
            <el-form-item class="EditNewFormBtns">
              <div class="EditNewFormItemName">一、基本信息</div>
            </el-form-item>
            <el-form-item class="EditNewFormItemTube">
              <div class="EditNewFormItemName"><span class="signSpan">* </span>申报人联系方式</div>
              <div class="EditNewFormItemIpt">
                <el-input v-model="Row.mobile" disabled placeholder="请填写申报人联系方式"></el-input>
              </div>
            </el-form-item>
            <el-form-item class="EditNewFormItemTube">
              <div class="EditNewFormItemName"><span class="signSpan">* </span>申报人姓名</div>
              <div class="EditNewFormItemIpt">
                 <el-input v-model="Row.username" disabled placeholder="请填写申报人姓名"></el-input>
              </div>
            </el-form-item>
            <el-form-item class="EditNewFormItemTube">
              <div class="EditNewFormItemName"><span class="signSpan">* </span>申报人身份证号码</div>
              <div class="EditNewFormItemIpt">
                <el-input v-model="Row.id_card" placeholder="请填写申报人身份证号码"></el-input>
              </div>
            </el-form-item>
            <el-form-item class="EditNewFormItemTube">
              <div class="EditNewFormItemName"><span class="signSpan">* </span>单位性质</div>
              <div class="EditNewFormItemIpt">
                <el-select v-model="Row.company_type" placeholder="请选择单位性质">
                  <el-option v-for="(item, i) in BBBBB_list.company_type" :key="i" :label="item" :value="i"></el-option>
                </el-select>
              </div>
            </el-form-item>
            <el-form-item class="EditNewFormItemTube">
              <div class="EditNewFormItemName"><span class="signSpan">* </span>所属站点</div>
              <div class="EditNewFormItemIpt">
                <el-select v-model="Row.site_id" placeholder="请选择所属站点" disabled>
                  <el-option v-for="item in SiteList" :key="item.site_id" :label="item.name" :value="item.site_id"></el-option>
                </el-select>
              </div>
            </el-form-item>
            <el-form-item class="EditNewFormItemTube">
              <div class="EditNewFormItemName"><span class="signSpan">* </span>所属业务人员</div>
              <div class="EditNewFormItemIpt">
                <el-select v-model="Row.business_id" placeholder="请选择所属业务人员" disabled>
                  <el-option v-for="item in BusinessList" :key="item.id" :label="item.username"  :value="item.id"></el-option>
                </el-select>
              </div>
            </el-form-item>
            <el-form-item class="EditNewFormItemTube">
              <div class="EditNewFormItemName"><span class="signSpan">* </span>工作单位（与推荐单位一致）</div>
              <div class="EditNewFormItemIpt">
                <el-input v-model="Row.company" placeholder="请选择购买项目"></el-input>
              </div>
            </el-form-item>
            <el-form-item class="EditNewFormBtns">
              <div class="EditNewFormItemName">二、参保信息</div>
            </el-form-item>
            <el-form-item class="EditNewFormItemTube">
              <div class="EditNewFormItemName"><span class="signSpan">* </span>参保类型</div>
              <div class="EditNewFormItemIpt">
                <el-select v-model="Row.social_security_type" placeholder="请选择参保类型">
                  <el-option v-for="(item, i) in BBBBB_list.social_security_type" :key="i" :label="item" :value="i"></el-option>
                </el-select>
              </div>
            </el-form-item>
            <el-form-item class="EditNewFormItemTube">
              <div class="EditNewFormItemName"><span class="signSpan">* </span>参保情况</div>
              <div class="EditNewFormItemIpt">
                <el-select v-model="Row.social_security_situation" placeholder="请选择参保情况">
                  <el-option v-for="(item, i) in BBBBB_list.social_security_situation" :key="i" :label="item" :value="i"></el-option>
                </el-select>
              </div>
            </el-form-item>
            <el-form-item class="EditNewFormItemTube">
              <div class="EditNewFormItemName" style="margin-bottom:1px"><span class="signSpan">* </span>社保缴纳单位</div>
              <div class="EditNewFormItemIpt">
                <el-input v-model="Row.social_security_company" placeholder="请填写社保缴纳单位"></el-input>    
              </div>
            </el-form-item>
            <el-form-item class="EditNewFormBtns">
              <div class="EditNewFormItemName">三、学历信息</div>
            </el-form-item>
            <el-form-item class="EditNewFormItemTube">
              <div class="EditNewFormItemName"><span class="signSpan">* </span>毕业院校（一）</div>
              <div class="EditNewFormItemIpt">
                <el-input v-model="Row.graduate_university" placeholder="请选择购买项目"></el-input>
              </div>
            </el-form-item>
            <el-form-item class="EditNewFormItemTube">
              <div class="EditNewFormItemName"><span class="signSpan">* </span>毕业时间</div>
              <div class="EditNewFormItemIpt">
                <el-date-picker style="width: 100%" v-model="Row.graduate_time" type="date" placeholder="选择毕业时间" :clearable="false" ></el-date-picker>
              </div>
            </el-form-item>
            <el-form-item class="EditNewFormItemTube">
              <div class="EditNewFormItemName"><span class="signSpan">* </span>所学专业</div>
              <div class="EditNewFormItemIpt">
                <el-input v-model="Row.graduate_major" placeholder="请填写所学专业"></el-input>
              </div>
            </el-form-item>
            <el-form-item class="EditNewFormItemTube">
              <div class="EditNewFormItemName"><span class="signSpan">* </span>学历形式（一）</div>
              <div class="EditNewFormItemIpt">
                <el-select v-model="Row.education_form" placeholder="请选择学历形式">
                  <el-option v-for="(item, i) in BBBBB_list.education_form" :key="i" :label="item" :value="i"></el-option>
                </el-select>
              </div>
            </el-form-item>
            <el-form-item class="EditNewFormItemTube">
              <div class="EditNewFormItemName"><span class="signSpan">* </span>学历层次（一）</div>
              <div class="EditNewFormItemIpt">
                <el-select v-model="Row.education_arrangement" placeholder="请选择学历层次">
                  <el-option v-for="(item, i) in BBBBB_list.education_arrangement" :key="i" :label="item" :value="i"></el-option>
                </el-select>
              </div>
            </el-form-item>
            <el-form-item class="EditNewFormBtns">
              <div class="EditNewFormItemName">四、继续教育学时</div>
            </el-form-item>
            <el-form-item class="EditNewFormItemTube">
              <div class="EditNewFormItemName"><span class="signSpan">* </span>继续教育要求</div>
              <div class="EditNewFormItemIpt">
                <el-select v-model="Row.study_time" placeholder="请选择继续教育要求">
                  <el-option v-for="(item, i) in BBBBB_list.study_time" :key="i" :label="item" :value="i"></el-option>
                </el-select>   
              </div>
            </el-form-item>
            <el-form-item class="EditNewFormItemTube">
              <div class="EditNewFormItemName"><span class="signSpan">* </span>公需课状况</div>
              <div class="EditNewFormItemIpt">
                <el-select v-model="Row.study_common" placeholder="请选择公需课">
                  <el-option v-for="(item, i) in BBBBB_list.study_common" :key="i" :label="item" :value="i"></el-option>
                </el-select>   
              </div>
            </el-form-item>
            <el-form-item class="EditNewFormItemTube">
              <div class="EditNewFormItemName"><span class="signSpan">* </span>专业课状况</div>
              <div class="EditNewFormItemIpt">
                <el-select v-model="Row.study_special" placeholder="请选择专业课">
                  <el-option v-for="(item, i) in BBBBB_list.study_special" :key="i" :label="item" :value="i"></el-option>
                </el-select>     
              </div>
            </el-form-item>
            <el-form-item class="EditNewFormBtns">
              <div class="EditNewFormItemName">五、申报信息</div>
            </el-form-item>
            <el-form-item class="EditNewFormItemTube">
              <div class="EditNewFormItemName"><span class="signSpan">* </span>申报地区
                <span class="Modification" style="margin-right:0" @click="Clue(1)">内容管理</span>
              </div>
              <div class="EditNewFormItemIpt">
                <el-select v-model="Row.declare_address" placeholder="请选择申报地区">
                  <el-option v-for="item in BBBBB_content.sales_declare_address" :key="item.value" :label="item.content" :value="item.value"></el-option>
                </el-select>  
              </div>
            </el-form-item>
            <el-form-item class="EditNewFormItemTube">
              <div class="EditNewFormItemName" style="margin-bottom:1px"><span class="signSpan">* </span>申报年份</div>
              <div class="EditNewFormItemIpt">
                <el-date-picker style="width: 100%" v-model="Row.declare_year" type="year" placeholder="选择毕业时间" :clearable="false" ></el-date-picker>
              </div>
            </el-form-item>
            <el-form-item class="EditNewFormItemTube">
              <div class="EditNewFormItemName"><span class="signSpan">* </span>申报系列
                <span class="Modification" style="margin-right:0" @click="Clue(2)">内容管理</span>
              </div>
              <div class="EditNewFormItemIpt">
                <el-select v-model="Row.declare_series" placeholder="请选择申报系列">
                  <el-option v-for="(item, i) in BBBBB_content.sales_declare_series" :key="i" :label="item.content" :value="i"></el-option>
                </el-select>
              </div>
            </el-form-item>
            <el-form-item class="EditNewFormItemTube">
              <div class="EditNewFormItemName"><span class="signSpan">* </span>申报具体专业
                <span class="Modification" style="margin-right:0" @click="Clue(3)">内容管理</span>
              </div>
              <div class="EditNewFormItemIpt">
                <el-select v-model="Row.declare_major" placeholder="请选择申报具体专业">
                  <el-option v-for="item in BBBBB_content.sales_declare_major" :key="item.value" :label="item.content" :value="item.value"></el-option>
                </el-select>
              </div>
            </el-form-item>
            <el-form-item class="EditNewFormItemTube">
              <div class="EditNewFormItemName"><span class="signSpan">* </span>评价方式
                <span class="Modification" style="margin-right:0" @click="Clue(4)">内容管理</span>
              </div>
              <div class="EditNewFormItemIpt">
                <el-select v-model="Row.evaluate_type" placeholder="请选择评价方式">
                  <el-option v-for="(item, i) in BBBBB_content.sales_evaluate_type" :key="i" :label="item.content" :value="i"></el-option>
                </el-select>
              </div>
            </el-form-item>
            <el-form-item class="EditNewFormBtns">
              <div class="EditNewFormItemName">六、申报人账号</div>
            </el-form-item>
            <el-form-item class="EditNewFormItemTube">
              <div class="EditNewFormItemName"><span class="signSpan">* </span>网报平台账号</div>
              <div class="EditNewFormItemIpt">
                <el-input v-model="Row.platform_username" placeholder="请填写正确的登录账号信息"></el-input>
              </div>
            </el-form-item>
            <el-form-item class="EditNewFormItemTube">
              <div class="EditNewFormItemName"><span class="signSpan">* </span>网报平台密码</div>
              <div class="EditNewFormItemIpt">
                <el-input v-model="Row.platform_password" placeholder="请输入相对应的网报平台密码"></el-input>
              </div>
            </el-form-item>
            <el-form-item class="EditNewFormBtns">
              <div class="EditNewFormItemName">七、备注信息</div>
              <div class="EditNewFormItemIpt">
                <el-input type="textarea" v-model="Row.remark" placeholder="可以将客户其他重要的信息备注到这里" ></el-input>  
              </div>
            </el-form-item>
            <el-form-item class="EditNewFormBtns">
              <div class="FormBtns">
                <div v-if="resetForm == 'xinjian'" class="FormBtnsQuery" @click="BtnAdd(1)">创建</div>
                <div v-if="resetForm == 'baocun'" class="FormBtnsQuery" @click="BtnAdd(2)">保存</div>
                <div class="FormBtnsClose" @click="Close()">取消</div>
              </div>
            </el-form-item>
          </div>
          <div class="EditNewForm" v-if="isRow == 3">
            <el-form-item class="EditNewFormBtns">
              <div class="speed">全部进程信息</div>
            </el-form-item>
            <el-form-item class="EditNewFormBtns TRRE" v-if="FlowList.length != 0">
              <el-scrollbar height="100%">
                <div id="time-line">
                  <div class="item" v-for="item in FlowList" :key="item.index">
                    <div class="ytyt">
                      <div class="item-index">{{ item.createtime }}</div>
                      <div class="item-content">{{ item.info }}</div>
                      <img class="Rtt_im22g" v-for="item in item.imgs_url" :key="item" :label="item" :value="item" :src="item"   />
                    </div>
                  </div>
                </div>
              </el-scrollbar>
            </el-form-item>
            <el-form-item class="EditNewFormBtns">
              <div class="EditNewFormItemName"><span class="signSpan">* </span>文字记录</div>
              <div class="EditNewFormItemIpt">
                <el-input type="textarea" v-model="Info_desc" placeholder="请将每次沟通、维护、跟单、咨询等相关信息记录在这里，为了提高效率"></el-input>
              </div>
            </el-form-item>
            <el-form-item class="EditNewFormBtns">
              <div class="EditNewFormItemName">图片线索（选填）</div>
              <div class="EditNewFormItemIpt">
                <el-upload
                  :action="upload"
                  :headers="heads"
                  list-type="picture-card"
                  :on-success="UP_Success2"
                  :on-remove="HAndemove2"
                  :on-preview="handlePictureCardPreview">
                  <i class="el-icon-plus"></i>
                  <div class="el_asff">上传图片</div>
                </el-upload>
              </div>
            </el-form-item>
            <el-form-item class="EditNewFormBtns">
              <div class="FormBtns">
                <div class="FormBtnsQuery" style="float:left;margin-left: -24px;" @click="updateBtn()">更新进程</div>
              </div>
            </el-form-item>
          </div>
          <div class="EditNewForm" v-if="isRow == 4">
            <el-form-item class="EditNewFormItem" v-for="item in ProressList" :key="item.index">
              <div class="EditNewFormItemName">{{item.title}}</div>
              <div class="EditNewFormItemIpt">
                <el-progress :text-inside="true" :stroke-width="26" :percentage="item.progress"></el-progress>
              </div>
              <div class="Riddfsa" :class="idw == item.id ? 'Ridd_activ' : ''" >{{item.content}}</div>
              <div class="Riddfsa2" style="margin-right:13px" v-if="idw != item.id" @click="Zhankai(item.id)">展开</div>
              <div class="Riddfsa2" style="margin-right:13px" v-if="idw == item.id" @click="Zhankai(item.id)">收回</div>
              <div class="Riddfsa2" @click="Lishi(item)">历时进度</div>
            </el-form-item>
            <el-form-item class="EditNewFormBtns">
              <div class="FormBtns">
                <div class="FormBtnsQuery" style="float:left;margin-left: -24px;" @click="editVisible2 = true">编辑</div>
              </div>
            </el-form-item>
          </div>
          <div class="EditNewForm" v-if="isRow == 5">
            <el-table
              :data="FileList_TEXT"
              border
              style="width: 97%;">
              <el-table-column prop="createtime" align="center" label="上传时间"> </el-table-column>
              <el-table-column prop="name" align="center" label="文件名称"> </el-table-column>
              <el-table-column prop="filesize" align="center" width="100" label="文件大小"> </el-table-column>
              <el-table-column prop="staff.realname" align="center" width="100" label="上传者"> </el-table-column>
              <el-table-column label="操作" align="center">
                <template #default="scope">
                  <div class="mui">
                    <div class="mmim "> 
                      <a style="color:#1890FF" :href="scope.row.full_filepath" :download="scope.row.name + '.' + scope.row.format">下载</a>
                    </div>
                    <div class="mmim " @click="handleEdit2(scope.row)">删除</div>
                  </div>  
                </template>
              </el-table-column>
            </el-table>
            <div class="NavLiReset tj" @click="editVisible7 = true">添加</div>
          </div>
        </el-form>
      </div>
    </div> 


     <!-- 转移 -->
      <el-dialog title="转移订单" v-model="editVisible8" width="30%" class="">
        <el-form ref="form" label-width="100px" >
          <div>
            <el-form-item label="服务对象：">
              <el-input v-model="Row2.TA_name222" disabled></el-input>
            </el-form-item>
            <el-form-item label="报考院校：">
               <el-input v-model="Row2.TA_name111" disabled></el-input>
            </el-form-item>
            <el-form-item label="员工：">
              <el-select v-model="Row2.staff_id" placeholder="请选择人员">
                <el-option v-for="item in StaFFList" :key="item.id" :label="item.realname" :value="item.id"></el-option>
              </el-select>
            </el-form-item>
          </div>
        </el-form>
        <template #footer>
          <span class="dialog-footer">
            <el-button type="primary" @click="saveEdit2(3)">确 认</el-button>
          </span>
        </template>
      </el-dialog>

    <!--全部线索类型管理-->
    <el-dialog title="内容管理" v-model="dialogVisib" width="36%"> 
      <div>
        <el-table
        :data="ClueData"
        class="table"
        header-cell-class-name="table-header"
        @selection-change="handleSelectionChange">
        <el-table-column label="线索类型" width="240" align="center" >
          <template #default="scope" >
              <div>
                <el-input type="text" :disabled = "scope.row.type == 'default' ? true : false"  v-model="scope.row.content" placeholder="输入内容"></el-input>
              </div>
          </template>
        </el-table-column>
        <el-table-column label="设置" width="180" align="center">
            <template #default="scope">
            <div v-if="scope.row.type == 'default'">预设</div>
            <div v-if="scope.row.type == 'custom'">自定义</div>
            </template>
        </el-table-column>
        <el-table-column label="是否启用" width="120">
          <template #default="scope" >
            <div class="el_butto rere dsdtrt">
                <el-switch
                  :disabled="scope.row.type == 'default' ? true : false "
                  v-model="scope.row.status"
                  active-value = 'normal'
                  inactive-value = 'hidden'
                  active-color="#1890FF"
                  @change="changeSwitch($event,scope.row,scope.$index)"
                  inactive-color="#dcdfe6">
                </el-switch>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="100" align="center" >
          <template #default="scope" >
            <div class="el_butto rere dsdtrt">
                <i class="el-icon-delete" @click="haeDete(scope.row)"></i>
            </div>
          </template>
        </el-table-column>
      </el-table>
      </div>
      <div class="YADD" @click="CreateAdd(AFF)"><i class="el-icon-plus"></i>创建内容</div>
      <div class="Dityo">
        <div class="dialog-footer">
          <el-button type="primary" @click="determine()">确 定</el-button>
        </div>
      </div>  
    </el-dialog>

    <!--全部线索类型管理-->
    <el-dialog title="查看合同" v-model="editVisible3" width="47.3%"> 
      <div class="EditNewForm hhh" style="padding:0">
        <el-form-item class="EditNewFormBtns">
          <div class="EditNewFormItemName">基本信息</div>
        </el-form-item>
        <el-form-item class="EditNewFormItemTube">
          <div class="EditNewFormItemName">姓名</div>
          <div class="EditNewFormItemIpt">
            <el-input v-model="Customer.username" placeholder="请填写客户姓名" disabled></el-input>
          </div>
        </el-form-item>
        <el-form-item class="EditNewFormItemTube">
          <div class="EditNewFormItemName">联系方式</div>
          <div class="EditNewFormItemIpt">
            <el-input v-model="Customer.mobile" placeholder="请填写客户手机号码" disabled></el-input>
          </div>
        </el-form-item>
        <el-form-item class="EditNewFormItemTube">
          <div class="EditNewFormItemName">客户昵称</div>
          <div class="EditNewFormItemIpt">
            <el-input v-model="Customer.username" placeholder="请填写客户使用的昵称" disabled></el-input>
          </div>
        </el-form-item>
        <el-form-item class="EditNewFormBtns">
          <div class="EditNewFormItemName">基本信息</div>
        </el-form-item>
        <el-form-item class="EditNewFormBtns">
          <div class="EditNewFormItemName"><span>所有的订单</span><span>{{Tongji.total}}</span></div>
          <div class="EditNewFormItemName"><span>未完成订单</span><span>{{Tongji.unfinish}}个</span></div>
          <div class="EditNewFormItemName"><span>已退费的订单</span><span>{{Tongji.refund}}个</span></div>
        </el-form-item>
        <el-form-item class="EditNewFormBtns">
          <el-table
            :data="OrderList"
            border
            align="center"
            style="width: 100%; margin-top: 20px">
            <el-table-column prop="createtime" label="购买时间" align="center" width="130"> </el-table-column>
            <el-table-column  prop="order_no" align="center" width="177" label="订单号"> </el-table-column>
            <el-table-column prop="goodsone.goods_name" align="center" width="120" label="购买项目"> </el-table-column>
            <el-table-column prop="goodsone.business_username"  width="102" align="center" label="服务商"> </el-table-column>
            <el-table-column prop="pay_type_text" align="center" width="90" label="支付方式"> </el-table-column>   
            <el-table-column prop="pay_price" align="center" width="90" label="支付金额"> </el-table-column>  
            <el-table-column prop="order_status_text" align="center" width="80" label="订单状态"> </el-table-column>   
            <el-table-column label="操作" align="center"  width="70">
              <template #default="scope">
                <div class="mmim" @click="handleEdit(scope.row)">查看</div>
              </template>
            </el-table-column>
          </el-table>
        </el-form-item>
        <el-form-item class="EditNewFormBtns">
          <div class="EditNewFormItemName">附加资料</div>
        </el-form-item>
        <el-form-item class="EditNewFormBtns">
          <el-table :data="FileList" border style="width: 100%; margin-top: 20px">
            <el-table-column prop="createtime" align="center" label="上传时间"> </el-table-column>
            <el-table-column prop="name" align="center" label="文件名称"> </el-table-column>
            <el-table-column prop="filesize" align="center" width="100" label="文件大小"> </el-table-column>
            <el-table-column prop="staff.realname" align="center" width="100" label="上传者"> </el-table-column>
            <el-table-column label="操作" align="center">
              <template #default="scope">
                <div class="mui">
                  <div class="mmim "> 
                    <a style="color:#1890FF" :href="scope.row.full_filepath" :download="scope.row.name + '.' + scope.row.format">下载</a>
                  </div>
                  <div class="mmim " @click="handleEdit2(scope.row)">删除</div>
                </div>
              </template>
            </el-table-column>
          </el-table>
          <div class="NavLiReset tj" @click="editVisible7 = true">添加</div>
        </el-form-item>
      </div>
    </el-dialog>

    <!-- 导入资料 -->
    <el-dialog title="导入资料" v-model="editVisible7" width="31%">
        <el-form ref="form" label-width="120px">
          <div class="DDGGD">
          <el-form-item label="选择导入文件：" style="margin-bottom:5px">
            <div class="el-form-item__content">
              <el-upload
                  accept=".xls,.xlsx"
                  :action="upload"
                  :headers="heads"
                  :multiple="false"
                  :file-list="fileList2"
                  :on-success="UP_Success4"
                  :on-remove="fileRemove">
                  <el-button size="small" type="primary">选择文件</el-button>
                  <div class="el-upload__tip">一次只能上传一个xls/xlsx文件，且不超过10M</div>
              </el-upload>
          </div>
          </el-form-item>
          </div>
        </el-form>
        <template #footer>
          <span class="dialog-footer">
            <el-button type="primary" @click="File_Sure2()">确 认</el-button>
          </span>
        </template>
    </el-dialog>

    <!-- 业务进度编辑 -->
    <el-dialog title="业务进度编辑" v-model="editVisible2" width="36%" class="">
        <div class="DDGGD" v-for="item in ProressList_W" :key="item">
          <el-input class="Dssd_inopu" style="display: block;width:200px!important" v-model="item.title" placeholder="填写标题"></el-input>
          <el-input class="Dssd_inopu" style="width:200px" type="number" v-model="item.progress" placeholder="填写进度比"></el-input>
          <el-input class="Dssd_inopu2" style="width: 61.4%;" v-model="item.content" placeholder="填写本阶段的办理进度情况，如：目前只剩下业绩未交我方"></el-input>
          <div style="border-bottom: 1px dashed #ccc;margin: 8px;"></div>
        </div>
        <div @click="Pro_bj" style="color:#1890FF;cursor: pointer;">
          创建新的阶段
        </div>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="editVisible2 = false" style="margin-right:15px">取 消</el-button>
          <el-button type="primary" @click="saveEdit2(2)">确 认</el-button>
        </span>
      </template>
    </el-dialog>

    <!-- 历时进度信息 -->
    <el-dialog title="历时进度信息" v-model="editVisible4" width="32%" class="">
      <div class="TRRE" style="margin:0px 24px 0 1px;height:300px" v-if="FlowList2.length != 0">
        <el-scrollbar height="100%">
          <div id="time-line">
            <div class="item" v-for="item in FlowList2" :key="item.index">
              <div class="ytyt">
                <div class="item-index">{{ item.createtime }}</div>
                <div class="item-content">{{ item.content }}</div>
              </div>
            </div>
          </div>
        </el-scrollbar>
      </div>
    </el-dialog>

  </div>
</template>

<script>
import axios from 'axios'
import  TextMoth  from "../../components/utils.vue";
import { regionData, CodeToText } from "element-china-area-data";
import Intermediate from './components/Intermediate.vue'
import Seniortitla from './components/Seniortitla.vue'
import Credithours from './components/Credithours.vue'
import Thesistitla from './components/Thesistitla.vue'
export default {
  name: "basetable",
  components:{
    Intermediate,
    Seniortitla,
    Credithours,
    Thesistitla
  },
  data() {
    return{
      upload:'https://rest-api.duxin365.com/admin/ajax/upload',
      upload2:'https://rest-api.duxin365.com/admin/ajax/upload',
      heads:{token:''},
      activeIndex:'1',
      acndex:'1',
      type:'self',
      total:'',
      titleMath:'',
      resetForm:'',
      editId:'',  
      dataId:'',
      isRow:'',
      order:'',
      val2:'买家本人', 
      fieldKey:'',   
      D_yan2:'',
      File2:null,
      chakan111:'',
      File2_name:'',
      Info_desc:'',
      content:'',
      idw:0,
      Customer_id:'', 
      AFF:'', 
      dialoge:0,
      isShow: 1,
      multipleSelection_id:'',
      BBBBB_list:{},
      BBBBB_content:{},
      salesaddress:[],
      salesseries:[],
      salesmajor:[],
      salestype:[],
      BusinessList:[],
      SiteList:[],      //站点列表
      FlowList:[],  
      FlowList2:[],  
      tableData:[],
      Row_Eurl2:[],
      Row_Eurl:[],
      ProressList:[],
      FileList_TEXT:[],
      fileList2:[],
      StaFFList:[],
      ProressList_W: [],
      multipleSelection:[],
      MMD:[{id:'self',name:'我的线索'},{id:'common',name:'全部线索'}],
      ruleFormRef:false,
      editVisible4:false,
      editVisible2:false,
      editVisible7:false,
      editVisible3:false,
      dialogVisib:false,
      editVisible8:false,
      page: {   // 分页
        currentPage: 1,
        pageSize: 10,
      },
      search: {  //搜索参数
        declare_year : "",     
        evaluate_type : "",    
        declare_address : "",   
        business_id : "",       
        company_type : "",     
        education_arrangement : "",     
        declare_series : "",     
        site_id : "",     
        keyword : '' 
      },
      Row:{ 
        username :  "", //申报人姓名
        mobile :  "", //申报人联系方式
        id_card :  "", //申报人身份证号
        company :  "", //工作单位
        company_type :  "", //单位性质
        site_id :  "", //所属站点
        business_id :  "", //服务商ID
        order_id :  "", //订单ID
        customer_id :  "", //客户ID
        social_security_type :  "", //参保类型
        social_security_situation :  "", //参保情况
        social_security_company :  "", //社保缴纳单位
        graduate_university :  "", //毕业院校
        education_form :  "", //学历形式
        education_arrangement :  "", //学历层次
        graduate_time :  "", //毕业时间
        graduate_major :  "", //所学专业
        study_time :  "", //继续教育状况
        study_common :  "", //公共课状况
        study_special :  "", //专业课状况
        declare_address :  "", //申报地区
        declare_year :  "", //申报年份
        declare_series :  "", //申报系列
        declare_major :  "", //申报具体专业
        evaluate_type :  "", //评价方式
        platform_username :  "", //网报平台账号
        platform_password :  "", //网报平台密码
        remark :  "" //备注信息
      },
      Order:{
        goodsone:{},
        order_status_text:'',
      },
      Row2:{
        TA_name222:'',
        TA_name111:'',
        staff_id:'',
      }
    }
  },
  mounted(){
    var that = this
    let token = window.localStorage.getItem("accessToken")
    that.heads.token = token
    that.staff()
    that.loadTable()
  },
  methods: {
    loadTable() {
      var that = this
      axios({
        method: 'post',
        url: '/admin/workbench/sales/primaryList',
        data: {
          page: that.page.currentPage,
          limit: that.page.pageSize,
          type: that.type,
          filter: that.search,
        },
      }).then(function (res) {
        that.tableData = res.data.data.rows
        that.total = res.data.data.total
        that.$forceUpdate()
      })
    },
    //创建
    showAdd(){
      var that = this
      that.ruleFormRef = true;
      that.titleMath = '创建人员'
      that.isRow = 1
    },
    BtnAdd(e){
      var that = this
      let y1 =''
      let y2 =''
      that.Row.customer_id = that.Customer_id
      if(that.Row.graduate_time != '') {
        y1 = that.formatDate(that.Row.graduate_time);
        that.Row.graduate_time = y1
      }
      if(that.Row.declare_year != '') {
        y2 = that.formatDate2(that.Row.declare_year);
        that.Row.declare_year = y2
      }
      if( e == 1 ){
        axios({
          method: 'post',
          url: '/admin/workbench/sales/addPrimary',
          data: {
            row: that.Row
          },
        }).then(function (res) {
          if (res.data.code == 0) {
            that.$message.warning(res.data.msg);
          } else {
            that.$message.success(res.data.msg);
            that.ruleFormRef = false
            that.loadTable();
          }
        }) 
      }else if( e == 2 ){
        axios({
          method: 'PUT',
          url: '/admin/workbench/sales/editPrimary',
          data: {
            id:that.editId,
            row: that.Row
          },
        }).then(function (res) {
          if (res.data.code == 0) {
            that.$message.warning(res.data.msg);
          } else {
            that.$message.success(res.data.msg);
            that.ruleFormRef = false
            that.loadTable();
          }
        }) 
      }
    },
    // 获取编辑详情 
    HandleEdit(data) {                       
      var that = this
      that.staff()
      that.ruleFormRef = true;
      that.resetForm = 'baocun';
      that.titleMath = '编辑人员'
      that.editId = data.id
      that.isRow = 2
      that.chakan111 = 1
      that.Customer_id = data.customer_id
      axios.get('/admin/workbench/sales/primaryInfo', {  
        params: {
          id: data.id
        }
      }).then(function (res) {
        that.Row = res.data.data.row
      })
    },
    //删除
    HandleDel(data) {
      var that = this
      var arr = []
      arr.push(data.id)
      that.$confirm("确定要删除吗？", "提示", {
        type: "primary"
      }).then(() => {
        axios({
          method: 'DELETE',
          url: '/admin/workbench/sales/delPrimary',
          data: {
            ids: arr
          }
        }).then(function (res) {
          if (res.data.code == 0) {
            that.$message.warning(res.data.msg);
          } else {
            that.$message.success(res.data.msg);
            that.loadTable();
          }
        })
      })
    },
    Add_Del() {     //批量删除          
      var that = this
      var Att = that.multipleSelection
      if (that.multipleSelection_id == null) {   
        that.$message.warning('请勾选信息');
      } else {
        that.$confirm("确定要删除吗？", "提示", {
          type: "primary"
        }).then(() => {
          axios({
            method: 'delete',
            url: '/admin/workbench/sales/delPrimary',
            data: {
              ids: that.multipleSelection_id
            },
          }).then(function (res) {
              if (res.data.code == 0) {
                that.$message.warning(res.data.msg);
              } else {
                that.$message.success(res.data.msg);
                that.loadTable()
              }
          }) 
        })
      } 
    },
    //转移
    HandleTransfer(data){
      var that = this
      that.editVisible8 = true
      that.dataId = data.id
      that.yuangong()
      that.Row2.TA_name222 = data.username
      that.Row2.TA_name111 = data.declare_major_text
    },
    Chang_ses(e) {             // 切换我的线索
      var that = this
      that.type = e  
      that.loadTable()
    },
    //查询订单
    Query(){
      var that = this
      if( that.order != '' ){
        axios.get('/admin/order/order/getOrderbyOrderNo',{   // 设置选项 
          params:{
            order_no: that.order
          }
        }).then(function (res) {
        if (res.data.code == 0) {
          alert('请求失败');  
        } else{ 
          console.log(res.data.data.order)
            if (res.data.data.order == null) {
              that.D_yan2  = 2
              that.Order = {
                goodsone:{},
                order_status_text:'',
              }
            } else {
              that.D_yan2  = 1
              that.Order = res.data.data.order
              that.Row.username = res.data.data.order.goodsone.user_nickname
              that.Row.mobile = res.data.data.order.goodsone.user_mobile
              that.Row.site_id = res.data.data.order.site_id
              that.Row.business_id = parseInt(res.data.data.order.business_id)
              that.Row.order_id = res.data.data.order.id
              that.Customer_id = res.data.data.order.customer_id
            }
        }
      }) 
      } 
    },
    ChaKan(){
      this.isRow = 2
      this.resetForm = 'xinjian'
      this.staff()
    },
    flowRead() {  //线索进程
      var that = this
      axios.get('/admin/customer/flow/read',{   
            params:{
              customer_id: that.Customer_id,
              type: "primary", 
              source_id: that.editId,  
            }
        }).then(function (res) {
          if (res.data.code == 0) {
            alert('请求失败');  
          } else { 
            that.FlowList = res.data.data.flow_list
          }
      }) 
    },
    progressList(){   //业务进度信息
      var that = this
      axios({                                              
        method: 'post',
        url: '/admin/workbench/sales_info/progressList', 
        data: {
          type:'primary',
          source_id: that.editId
        },
      }).then(function (res) {
        console.log(res.data.data)
        if (res.data.code == 0) {
          that.$message.warning(res.data.msg);
        }else {
          that.ProressList = res.data.data.proressList 
          that.ProressList_W = res.data.data.proressList.slice()   //复制一个数组 
        }
      })
    },
    Zhankai(e) {         //办理进度  展开
      var that = this
      if (that.idw == e) {that.idw = 982} else {that.idw = e}
    },
    Lishi(e) {           //办理进度 历史信息
      var that = this
      that.editVisible4 = true
      that.FlowList2 = e.history
    },
    updateBtn(){  //更新进程
      var that = this
      axios({
        method: 'post',
        url: '/admin/customer/flow/add', 
        data: {
          row:{
            customer_id: that.Customer_id, //客户ID，客户管理时必填
            info: that.Info_desc, //进程的信息
            type: "primary",
            source_id: that.editId, //当前类型所在列表ID
            images: that.Row_Eurl2
          }
        },
      }).then(function (res) {
        if(res.data.code == 0) {
          that.$message.warning(res.data.msg);
          that.Info_desc = ''

        } else {
            that.$message.success(res.data.msg);
            that.Info_desc = ''
            that.flowRead()
        }
      })
    },
    Close() {  //关闭按钮
      var that = this
      that.ruleFormRef = false;
      that.Order = {}
      that.Row = {}
    },
    //业务进度编辑
    Pro_bj() {
      var that = this
      var Pror = that.ProressList_W
      var arr = {
        id:0,
        title:'',
        progress:'',
        content:''
      }
      Pror.push(arr)
      that.ProressList_W = Pror
    },
    saveEdit2(e) {             
      var that = this
      console.log(e);
      if( e == 2 ){
        axios({
          method: 'post',
          url: '/admin/workbench/sales_info/editProgress', 
          data: {
            type:'primary',
            source_id:that.editId,
            content:that.ProressList_W
          },
        }).then(function (res) {
            if (res.data.code == 0) {
              that.$message.warning(res.data.msg);
            } else{
                that.editVisible2 = false
                that.ProressList = res.data.data.proressList
             }
        })
      }else if( e == 3 ){
        axios({
          method: 'post',
          url: '/admin/workbench/sales/transferPrimary', 
          data: {
            id: that.dataId,
            row:{
              staff_id : that.Row2.staff_id
            }
          },
        }).then(function (res) {
            if (res.data.code == 0) {
              that.$message.warning(res.data.msg);
            } else{
                that.editVisible8 = false
                that.loadTable()
             }
        })
      }
    },
    workbench(){
      var that = this  
      axios({                                               //附件列表
        method: 'post',
        url: '/admin/workbench/sales_info/fileList', 
        data: {
          type:'primary',
          source_id: that.editId
        },
      }).then(function (res) {
        if (res.data.code == 0) {
          that.$message.warning(res.data.msg);
        }else {
          that.FileList_TEXT = res.data.data.fileList
          that.flowRead()
        }
      })
    },
    //附件
    handleEdit2(data) {        
      var that = this
      that.$confirm("确定要删除吗？", "提示", {
        type: "warning"
        }).then(() => {
        axios.delete('/admin/workbench/sales_info/delFile',{ 
            params:{
              id: data.id
            }
          }).then(function (res) {
          if (res.data.code == 0) {
              that.$message.warning(res.data.msg);
          } else{ 
            that.$message.success(res.data.msg);
            that.workbench()
          }
        })   
      })
    },
    File_Sure2() {          
      var that = this
      that.fileList2 = []
      axios({                                              
        method: 'post',
        url: '/admin/workbench/sales_info/addFile', 
        data: {
          row:{
            type:'primary',
            source_id:that.editId,
            name:that.File2_name,
            filepath:that.File2
          }
        },
      }).then(function (res) {
        if (res.data.code == 0) {
          that.$message.warning(res.data.msg);
        }else {
          that.editVisible7 = false
          that.workbench()
        }
      })
    },
    //内容管理
    Clue(e){
      this.dialogVisib = true
      this.dialoge = e
      console.log(this.dialoge);
      if( e == 1 ){
        this.ClueData = this.salesaddress
        this.titleMath = '添加内容管理'
        this.isInfo = 'sales_declare_address'
        this.AFF = 1
      }else if( e == 2 ){
        this.ClueData = this.salesseries
        this.titleMath = '添加内容管理'
        this.isInfo = 'sales_declare_series'
        this.AFF = 2
      }else if( e == 3 ){
        this.ClueData = this.salesmajor
        this.titleMath = '添加内容管理'
        this.isInfo = 'sales_declare_major'
        this.AFF = 3
      }else if( e == 4 ){
        this.ClueData = this.salestype
        this.titleMath = '添加内容管理'
        this.isInfo = 'sales_evaluate_type'
        this.AFF = 4
      }
    },
    CreateAdd(e){
      var that = this
      var srt = {
        content: "新加",
        status: 'normal',
        type: "custom",
        value:0,
      }
      if( e == 1 ){
        var ATT = that.salesaddress
        ATT.push(srt)
        that.salesaddress = ATT
      }else if( e == 2 ){
        var ADD = that.salesseries
        ADD.push(srt)
        that.salesseries = ADD
      }else if( e == 3){
        var ABB = that.salesmajor
        ABB.push(srt)
        that.salesmajor = ABB
      }else if( e == 4){
        var ACC = that.salestype
        ACC.push(srt)
        that.salestype = ACC
      }
    },
    //确定
    determine(){
      var that = this
      console.log(that.dialoge);
      if(that.dialoge == 1){
        that.fieldKey = 'sales_declare_address'
        that.content = that.salesaddress 
        that.daohang()
      }else if(that.dialoge == 2){
        that.fieldKey = 'sales_declare_series'
        that.content = that.salesseries 
        that.daohang()
      }else if(that.dialoge == 3){
        that.fieldKey = 'sales_declare_major'
        that.content = that.salesmajor 
        that.daohang()
      }else if(that.dialoge == 4){
        that.fieldKey = 'sales_evaluate_type'
        that.content = that.salestype 
        that.daohang()
      }
    },
    //导航删除
    haeDete(data){
      var that = this 
      let valueId = data.value
      var ATT = that.salesaddress
      var ADD = that.salesseries
      var ABB = that.salesmajor
      var ACC = that.salestype
      if (data.type == 'default') {
      } else {
        if(that.dialoge == 1){
          let index = ATT.findIndex(obj => obj.value  === valueId)
          ATT.splice(index, 1)
          that.salesaddress = ATT
        }else if(that.dialoge == 2){
          let index = ADD.findIndex(obj => obj.value  === valueId)
          ADD.splice(index, 1)
          that.salesseries = ADD
        }else if(that.dialoge == 3){
          let index = ABB.findIndex(obj => obj.value  === valueId)
          ABB.splice(index, 1)
          that.salesmajor = ABB
        }else if(that.dialoge == 4){
          let index = ACC.findIndex(obj => obj.value  === valueId)
          ACC.splice(index, 1)
          that.salestype = ACC
        }
      }
    },
    daohang(){  //添加接口
      var that = this 
      axios({
        method: 'post',
        url: '/admin/company/content/edit',
        data: {
          field_key: that.fieldKey, //内容标识
          content: that.content
        },
      }).then(function (res) {
          if (res.data.code == 0) {
            that.$message.warning(res.data.msg);
          } else {
            that.dialogVisib = false
            that.staff()
          }
      }) 
    },
    //新增编辑选项控制
    handleSelect2(key) {
      if (key == 1) {
        this.isRow = 2;
      } else if(key == 2){
        this.flowRead()
        this.isRow = 3
      } else if(key == 3){
        this.isRow = 4
        this.progressList() 
      } else if(key == 4){
        this.isRow = 5
        this.workbench()
      }
    },
    //搜索
    BtnSearch(e) {
      var that = this
      var time = ''
      that.page.currentPage = 1;
      if( e == false){
        that.search = {
          declare_year : "",     
          evaluate_type : "",    
          declare_address : "",   
          business_id : "",       
          company_type : "",     
          education_arrangement : "",     
          declare_series : "",     
          site_id : "",     
          keyword : '' 
        }
      }
      if (that.search.declare_year != '' ) {
        time = that.formatDate2(that.search.declare_year)
      }
      axios({
        method: 'post',
        url: '/admin/workbench/sales/primaryList',
        data: {
          page: that.page.currentPage,
          limit: that.page.pageSize,
          filter: {
            declare_year : time,     
            evaluate_type : that.search.evaluate_type,    
            declare_address : that.search.declare_address,   
            business_id : that.search.business_id,       
            company_type : that.search.company_type,     
            education_arrangement : that.search.education_arrangement,     
            declare_series : that.search.declare_series,     
            site_id : that.search.site_id,     
            keyword :that.search.keyword, 
          },
        },
      }).then(function (res) {
        that.tableData = res.data.data.rows
        that.total = res.data.data.total
        that.$forceUpdate()
      })
    },
    staff(){   //列表
      var that = this
      axios.get('/admin/workbench/sales/statisticsColumns',{   // 设置选项 
          params:{
            type: 'primary' 
          }
        }).then(function (res) {
        if (res.data.code == 0) {
          alert('请求失败');  
        } else {
             that.BBBBB_list = res.data.data.list
             that.BBBBB_content = res.data.data.content
             that.salesaddress = res.data.data.content.sales_declare_address
             that.salesseries = res.data.data.content.sales_declare_series
             that.salesmajor = res.data.data.content.sales_declare_major
             that.salestype = res.data.data.content.sales_evaluate_type
        }
      }) 
      axios.get('/admin/ajax/allBusiness',{   // 服务商列表
      }).then(function (res) {
        if (res.data.code == 0) {
          alert('请求失败');  
        } else{ 
          that.BusinessList = res.data.data
        }
      })
      axios.get('/admin/ajax/siteList',{   // 站点列表    
      }).then(function (res) {
        if (res.data.code == 0) {
          alert('请求失败');  
        } else{ 
          that.SiteList = res.data.data
        }
      }) 
    },
    yuangong(){
      var that = this
      axios.get('/admin/ajax/staffList',{   // 通讯录选择
      }).then(function (res) {
        if (res.code == 0) {
          alert('请求失败');  
        } else{ 
          that.StaFFList = res.data.data
        }
      }) 
    },
    formatDate(value) {    // 时间戳
      let date = new Date(value);
      let y = date.getFullYear();
      let MM = date.getMonth() + 1;
      MM = MM < 10 ? "0" + MM : MM;
      let d = date.getDate();
      d = d < 10 ? "0" + d : d;
      return y + "-" + MM + "-" + d
    },
    formatDate2(value) {    // 时间戳
        let date = new Date(value);
        let y = date.getFullYear();
        let MM = date.getMonth() + 1;
        MM = MM < 10 ? "0" + MM : MM;
        let d = date.getDate();
        d = d < 10 ? "0" + d : d;
        return y
    },
    handleCurrentChange(page) {
      var that = this
      that.page.currentPage = page;
      that.loadTable();
    },
    UP_Success2(e) {  //图片上传成功
      var that = this
      that.Row_Eurl2.push(e.data.url)
    },
    HAndemove2(file, fileList) {
      var that = this
      that.Row_Eurl2 = []
      for (let i = 0; i < fileList.length; i++) {
        const element = fileList[i];
        that.Row_Eurl2.push(element.response.data.url)   
      }
    },
    UP_Success(e) {  //图片上传成功
      var that = this
      that.Row_Eurl.push(e.data.url)
    },
    HAndemove(file, fileList) {
      var that = this
      that.Row_Eurl = []
      for (let i = 0; i < fileList.length; i++) {
        const element = fileList[i];
        that.Row_Eurl.push(element.response.data.url)
      }
    },
    UP_Success4(e, fileList2) {                //附件上传成功
      var that = this
      that.File2 = e.data.url
      that.File2_name = fileList2.name
    },
    //勾选
    Selection(e) {
      var that = this
      console.log(e);
      var arr = []
      var acc = []
      if (e.length == 0) {
        that.multipleSelection_id = null
      } else {
        for (let i = 0; i < e.length; i++) {
          const element = e[i];
          //主键ID
          arr.push(element.id)
          acc.push(element)
        }
        that.multipleSelection_id = arr
      }
      that.multipleSelection = acc
    },
    TextMoMo(e) {
      var arr = TextMoth.getData(e)
      return arr  
    },
    //导航选择
    handleSelect(key){
      if(key == 1){
        this.isShow = 1
      }else if(key == 2){
        this.isShow = 2
      }else if(key == 3){
        this.isShow = 3
      }else if(key == 4){
        this.isShow = 4
      }else if(key == 5){
        this.isShow = 5
      }
    },
  },
};
</script>


<style scoped>
.aaa {
  border-bottom: 1px solid #D9D9D9;
  width: 75%;
}
.aaa input{
  border: none !important;
  text-indent: 1.5em;
  width: 90%;
}
.Modification{
  color: #0081FF;
  float: right;
  cursor: pointer;
  margin-right: 16%;
}
.tj{
  margin-top: 30px;
  width: 98%;
}
.hhh .el-form-item{
  margin-bottom: 10px;
}
</style>